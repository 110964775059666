<template>
  <Page mode="jacket">
    <h1>{{ labels.Title }}</h1>
    <p>{{ labels.Introduction }}</p>
    <div class="vgs-form-indent">
      <ForgotPassowrdForm></ForgotPassowrdForm>
    </div>
    <div class="vgs-form-indent text-left">
       <RouteLink  route="signin" icon="arrow-right" :text="signIn.GoToSignIn"></RouteLink>
    </div>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import ForgotPassowrdForm from '@/forms/ForgotPasswordForm.vue'
import RouteLink from '@/structure/RouteLink.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import axios from "axios";

export default {
  mixins: [vcm,vvm, customLabels],
  name: 'ForgotPassword',
  components: {
    Page, 
    ForgotPassowrdForm,
    RouteLink
  },
  data() {
    return {
      labels: {
            Title: 'Sie haben Ihr Passwort vergessen?',
            Introduction: 'Bitte geben Sie hier Ihre E-Mail-Adresse ein, mit der Sie am E-Government-Portal registriert sind. An diese E-Mail-Adresse senden wir den Link zum Ändern des Passworts.',
            GoToSignIn: this.get_translation('App','GoToSignIn','label'),
      }
    }
  },
  async beforeCreate () {
    try {
      const Url = process.env.VUE_APP_BASE_URL + '/v1/pages/forgot-password'
      const response = await axios.get(Url, { params: {language: `${this.$root.config.localization.language}`} })
      if (response.data.responseCode === '200' && response.data.response) {
        this.labels.Title = response.data.response.Title
        this.labels.Introduction = response.data.response.Description;
      }
    } catch (e) {
      console.log('Error : ' + e)
    }
  },
}
</script>

<style scoped>

h1 { font-size: 1.875rem; line-height: 2.563rem;}
p { font-size: 1rem; line-height: 1.625rem; color: #121212; }

</style>

