<template>
  <b-form @submit.prevent="submit" class="forgot-password">
    <div class="form-group">
    <label class="vgs-ac-label" for="fm_email">{{
      forgotPasswordLabels.EmailPlaceholder
    }}</label>
    <b-form-input
      id="fm_email"
      :aria-label="forgotPasswordLabels.EmailPlaceholder"
      type="email"
      maxlength="50"
      @blur="$v.form.email.$touch()"
      v-model="form.email"     
      :placeholder="forgotPasswordLabels.EmailPlaceholder + ' *'"
      :class="{ 'is-invalid': $v.form.email.$error }"
      class="mb-3 rounded vgs-form-input"
    ></b-form-input>
    <div v-if="$v.form.email.$error" class="invalid-feedback error-class">
      <span v-if="!$v.form.email.required" class="float-left mb-2 mt-n2">{{ forgotPasswordLabels.EmailRequired }}</span>
      <span v-if="!$v.form.email.email && $v.form.email.required" class="float-left mb-2 mt-n2">{{ forgotPasswordLabels.InvalidEmail }}</span>
    </div>
     </div>
    <div class="vgs-error-messages" v-html="get_error_messages"></div>
    <b-button
      type="submit"
      variant="primary"
      class="w-100 p-3 rounded vgs-form-button"
      >{{forgotPasswordLabels.SendEmailButtonText}}</b-button
    >
    <div class="vcs-required">* {{ labels.RequiredField }}</div>
  </b-form>
</template>

<script>
import { vcm } from "@/mixins/vcm";
import { required , email   } from "vuelidate/lib/validators";
import { customLabels } from "@/mixins/customLabels";
import {mapActions} from "vuex";
export default {
  mixins: [vcm, customLabels],
  name: "ForgotPasswordForm",
  data() {
    return {
      isSuccess: false,
      labels: {
        Email: this.get_scope_translation("E-Mail-Adresse", "label"),
        RequiredField: this.get_scope_translation("RequiredField", "label")
      },
      form: {
        email: "",
      },
    };
  },
  validations: {
    form: {
      email:{ required, email },
    }
  },
  methods: {
    ...mapActions({
      sendFPEmail : "setting/sendForgotPasswordEmail"
    }),

    submit() {
      this.$v.$touch();
      if ( this.$v.form.$invalid) {
        return;
      }
      // call to API forget API
      this.sendFPEmail({Email : this.form.email}).then((result) => {
        if(result.responseCode === '200' && result.responseMessage === 'Ok') {
          this.isSuccess = true;
          this.add_error_message(this.forgotPasswordLabels.EmailSendSuccessMessage, this.isSuccess); 
        } else {
          if(result.responseCode === '200' && result.responseMessage === 'Incorrect Input') {
           let emailIdNotExists = 'E-Mail existiert nicht'  
           this.add_error_message(emailIdNotExists, this.isSuccess);
          } else {
            this.add_error_message(result.responseMessage, this.isSuccess);
          }
        }
      })

    }
  }
};
</script>

<style scoped>
.forgot-password{
  margin-top: 50px;
}
.vgs-form-indent form .form-control.is-invalid {
  background-image: none !important;
}
</style>
